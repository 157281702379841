
import { ref, defineComponent, reactive, toRefs, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { message } from 'ant-design-vue';
import { BuItem } from "@/views/Approve/SalesRoleApprove/ProgramHome/types";
import { getSubscribedBu } from "@/API/approve"
import { loadCommentsMaster, commentsSearchOption, OBProps, storeCommentsMaster, deleteCommentsMaster } from '@/API/setting'
import { ColumnsProps } from '@/types'
import { guid } from '@/utils/index'
import deepClone from '@/utils/deepClone'
import { useAllCheck } from '@/hooks/Program/useAllCheck'
export interface OBBProps {
    label: any; 
    value: string | number;
}


export interface LoadCommentsTableCellsProps{
    bu?: OBBProps;
    cn?: OBBProps;
    customerType?: OBBProps[];
    dealerType?: OBBProps[];
    en?: OBBProps;
    id: string;
    incentiveCategory?: OBBProps;
    offerType?: OBBProps[];
    position?: OBBProps;
    powerTrain?: OBBProps[];
    preconditionType?: OBBProps;
}
// export interface SearchObjProps {
//     buSelected: string[];
//     offerType: string[];
//     customerType: string[];
//     preconditionType: string[];
//     incentiveCategory: string;
//     searchSelect: OBBProps[];
// }
interface TableColProps {
    id: string; 
    display: string; 
    type: string;
}

interface OfferTypeProps {
    id: string;
    nameCn: string;
    nameEn: string;
    pathKey: string;
}

interface TableCellSelectProps {
    id: string;
    nameCn: string;
    nameEn: string;
    offerType: OfferTypeProps[];
}

export default defineComponent({
    components: {
        // VNodes: (_, { attrs }) => {
        //     return attrs.vnodes;
        // },
    },
    setup() {
        const route = useRoute();
        const fetching = ref<boolean>(false)
            
        const buSelectData = ref<BuItem[]>([])

        const searchObj = reactive<any>({
            buSelected: {},
            offerType: [],
            customerType: [],
            preconditionType: {},
            incentiveCategory: {},
            searchSelect: []
        })

        const tableCellSelect = ref<OBBProps[]>([])

        const columns = ref<ColumnsProps[]>([])
        
        const tableData = ref<LoadCommentsTableCellsProps[]>([])
        const tableCol = ref<TableColProps[]>([])

        // 下拉里面的全选，取消全选
        const { checked, currentChecked } = useAllCheck()

        // 获取business unit
        const getBuUnit = () => {
            getSubscribedBu().then(res => {
                buSelectData.value = res
            })
        }

        const handleAllCheck = (col: string, row: LoadCommentsTableCellsProps) => {
            checked.value = !checked.value
            if (checked.value) {
                row[col] = searchObj.searchSelect;
            } else {
                row[col] = []
            }
        }
        const searchData = (col: string) => {
            searchObj.searchSelect = []
            const params = {
                bu: searchObj.buSelected ? searchObj.buSelected : {},
                customerType: searchObj.customerType,
                incentiveCategory: searchObj.incentiveCategory,
                preconditionType: searchObj.preconditionType,
                offerType: searchObj.offerType,
            }
            for (const item in params) {
                if (Array.isArray(params[item])) {
                    if (params[item].length > 0) {
                        params[item] = params[item].map((it: any) => {
                            return {
                                id: it.value,
                                name: it.label
                            }
                        })
                    }
                    
                } else {
                    if (params[item] && Object.keys(params[item]).length > 0) {
                        params[item] = {
                            id: params[item].value,
                            name: params[item].label
                        }
                        delete params[item].key
                        delete params[item].label
                        delete params[item].value
                    }
                    
                }
            }
            commentsSearchOption({row: params, target: col}).then(res => {
                searchObj.searchSelect = res.length > 0 ? res.map((item: any) => {
                    return {
                        label: item.name,
                        value: item.id,
                    }
                }) : []
            })
        }
        
        const addNewRow = (tableCol: TableColProps[]) => {
            const param: LoadCommentsTableCellsProps = {
                id: guid(),
            };
            tableCol.forEach((item: TableColProps, index: number) => {
                // 和表头一致的空数据行
                if (item.type === 'SINGLE_SELECT') {
                    param[item.id] = {label: '', value: ''}
                } else if (item.type === 'MULTI_SELECT') {
                    param[item.id] = []
                } else {
                    param[item.id] = {id: '', name: ''}
                }
                
            })
            return param
        }


        // 如果数组只有一行默认数据时，只要Attribute不是Retail date，或是from或是to不为空时，都需插入一行
        // 当数组有2个以后数据，并且，Attribute都没有空才加一行
        // 1。当数组有2个以后数据，并且，Attribute都没有空才加一行
        // 2。不管有没有数据都需要加入一行
        const autoInsetRow = (tableCell: LoadCommentsTableCellsProps[], tableCol: TableColProps[], record?: LoadCommentsTableCellsProps) => {
            // 当改变一行中的数据，只是它是最后一行，就要触发自动加一行
            if (record) {
                const getIndex = tableCell.findIndex((item: LoadCommentsTableCellsProps) => item.id === record.id)
                if (getIndex === tableCell.length - 1) {
                    // const cloneObj = deepClone(record)
                    // cloneObj.id = guid()
                    // tableCell.push(cloneObj)
                    tableCell.push(addNewRow(tableCol))
                }

            // 不管有没有数据都需要加入一行
            } else {
                tableCell.push(addNewRow(tableCol))
            }
        }



                


        const handleSave = () => {
            const tableCells = deepClone(tableData.value)
            for (const key of tableCol.value) {
                 if (key.id !== 'cn' && key.id !== 'en') {

                        for (const val of tableCells) {

                            if (Array.isArray(val[key.id])) {
                                val[key.id] = val[key.id].map((item: any) => {
                                            if (typeof(item.value) === 'string') {
                                                return {
                                                    id: item.value,
                                                    name: item.label.children
                                                }
                                            } else {
                                                return {
                                                    id: item.value.value,
                                                    name: item.value.label
                                                }
                                            }
                            
                                    
                                    // return {label: item.name, value: item.id}
                                })
                            } else {
                                if (val[key.id] && val[key.id].value) {
                                    if (typeof(val[key.id].value) === 'string') {
                                        val[key.id] = {
                                            id: val[key.id].value,
                                            name: val[key.id].label.children
                                        }
                                        delete val[key.id].value
                                        delete val[key.id].label
                                    } else {
                                        val[key.id] = {
                                            id: val[key.id].value.value,
                                            name: val[key.id].value.label
                                        }
                                        delete val[key.id].value
                                        delete val[key.id].label
                                    }
                                }
                            }
                            
                            continue;
                        }
                }
            }
            const params = {
                tableCell: tableCells,
                tableCol: tableCol.value,
                "pageAt": 0,
                "pageSize": 0,
                "totalCount": 0
            }
            storeCommentsMaster(params).then(() => {
                message.success('successed')
            })
            
        }

        const handleFocus = (col: string, row: LoadCommentsTableCellsProps, select: string) => {
            tableCellSelect.value = []
            if (select === 'MULTI_SELECT') {
                row[col]  = []
            }
            autoInsetRow(tableData.value, tableCol.value, row)
            const rows = deepClone(row)
            for (const key in rows) {
                if (key !== 'cn' && key !== 'en' && key !== 'id') {
                    if (Array.isArray(rows[key])) {
                        rows[key] = rows[key].map((item: any) => {
                            if (typeof(item.value) === 'string') {
                                return {
                                    id: item.value,
                                    name: item.label.children
                                }
                            } else {
                                return {
                                    id: item.value.value,
                                    name: item.value.label
                                }
                            }
                            
                        })
                    } else {
                        if (rows[key] && rows[key].value) {
                            if (typeof(rows[key].value) === 'string') {
                                rows[key] = {
                                    id: rows[key].value,
                                    name: rows[key].label.children
                                }
                                delete rows[key].value
                                delete rows[key].label
                            } else {
                                rows[key] = {
                                    id: rows[key].value.value,
                                    name: rows[key].value.label
                                }
                                delete rows[key].value
                                delete rows[key].label
                            }
                        }
                        
                            
                    }

                }
                
            }
            commentsSearchOption({row: rows, target: col}).then(res => {
                tableCellSelect.value = res.map((item: any) => {
                    return {
                        value: item.id,
                        label: item.name,
                    }
                })
            })
            
        }


        const handleDelete = (record: string) => {
            deleteCommentsMaster({params: {id: record}}).then(res => {
                tableData.value = tableData.value.filter((item: LoadCommentsTableCellsProps) => item.id !== record)
            })
            
            
        }

        const getColumns = () => {
            columns.value = []
            tableCol.value.forEach((item: TableColProps, index: number) => {
                const params: ColumnsProps = {
                    title: item.display,
                    dataIndex: item.id,
                    slots: { customRender: item.id },
                }
                params.width = 210
                columns.value.push(params)
            })
            const params = {
                title: 'Operation',
                dataIndex: 'Operation',
                width: 100,
                slots: { customRender: 'action' },
            }
            columns.value.push(params)
        }
        const init = (searchFlag?: boolean) => {
            let params = {}
            if (searchFlag) {
                params = {
                        bu: searchObj.buSelected ? searchObj.buSelected : {},
                        customerType: searchObj.customerType,
                        incentiveCategory: searchObj.incentiveCategory,
                        preconditionType: searchObj.preconditionType,
                        offerType: searchObj.offerType,
                    }
                    
                    for (const item in params) {
                        if (Array.isArray(params[item]) && params[item].length > 0) {
                            params[item] = params[item].map((it: any) => {
                                return {
                                    id: it.value,
                                    name: it.label
                                }
                            })
                        } else {
                            if (params[item] && Object.keys(params[item]).length > 0) {
                                params[item] = {
                                    id: params[item].value,
                                    name: params[item].label
                                }
                                delete params[item].key
                                delete params[item].label
                                delete params[item].value
                            }
                            
                        }
                    }
            }
            loadCommentsMaster(params).then((res) => {
                if (res.tableCol.length > 0 && res.tableCell.length > 0) {
                    for (const key of res.tableCol) {
                        if (key.id !== 'cn' && key.id !== 'en') {
                            for (const val of res.tableCell) {
                                if (val[key.id]) {
                                    if (Array.isArray(val[key.id])) {
                                        val[key.id] = val[key.id].map((item: OBProps) => {
                                            return {label: item.name, value: item.id}
                                        })
                                    } else {
                                        val[key.id] = {
                                            value: val[key.id].id,
                                            label: val[key.id].name,
                                        }
                                    }
                                }
                                
                                
                                
                            }
                        }
                    }
                }
                
                tableData.value = res.tableCell as any
                tableCol.value = res.tableCol
                getColumns()
                autoInsetRow(tableData.value, tableCol.value)
            })
        }
        const getTableData = (searchFlag: boolean) => {
            init(searchFlag)
        }
        onMounted(() => {
            init()
            getBuUnit()
            
        })
        return {
            buSelectData,
            columns,
            tableData,
            handleSave,
            tableCellSelect,
            tableCol,
            handleDelete,
            handleFocus,
            fetching,
            checked,
            ...toRefs(searchObj),
            searchData,
            getTableData,
            handleAllCheck
        };
    },
});
