import { ref } from 'vue'
export const useAllCheck = () => {
    // 下拉里面的全选，取消全选
    const checked = ref(false)
    const currentChecked = (tableCellSelect: any, val: string[]) => {
        // .清空上一次的全选状态
        checked.value = false
        if (tableCellSelect.length === val.length) checked.value = true
    }
    return {
        checked,
        currentChecked
    }
}
